import React from "react";

import TwitterIcon from "mdi-react/TwitterIcon";
import FacebookIcon from "mdi-react/FacebookIcon";

import footerInceptia from "../assets/img/inceptia.png";
import footerCare from "../assets/img/CARE.jpg";
import footerAcc from "../assets/img/acc.jpg";

const Footer = () => (
  <footer className="footer has-text-centered">
      <div className="container">
          <p>Copyright © Rob Pivnick 2021. All Rights Reserved</p>
          <p>
              <a href="mailto:whatallkids@gmail.com">whatallkids@gmail.com</a>
          </p>
          <p style={{ margin: `1em auto` }}>
              <a
                  style={{ margin: `0 0.5em` }}
                  href="https://twitter.com/RPivnick"
              >
                  <TwitterIcon size={38} />
              </a>
              <a
                  style={{ margin: `0 0.5em` }}
                  href="https://www.facebook.com/whatallkids"
              >
                  <FacebookIcon size={38} />
              </a>
          </p>
          <p style={{
              display: `flex`,
              justifyContent: `center`,
              alignItems: `center`,
            }}>
              <a href="https://www.inceptia.org/" target="_blank" rel="noreferrer" style={{ margin: `0 1em` }}>
                  <img alt="Inceptia" src={footerInceptia} width="150" />
              </a>
              <a href="http://care4yourfuture.org/" target="_blank" rel="noreferrer" style={{ margin: `0 1em` }}>
                  <img alt="CARE" src={footerCare} width="150" />
              </a>
              <a href="http://jumpstart.org/" target="_blank" rel="noreferrer" style={{ margin: `0 1em` }}>
                  <img alt="Jumpstart" src={footerAcc} width="150" />
              </a>
          </p>
      </div>
  </footer>
)

export default Footer;