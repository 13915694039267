import React from "react";
import Navbar from "./Navbar";
import Footer from "./Footer";
import { Helmet } from "react-helmet";
import "../styles/global.sass";

const Layout = ({children}) => {
  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <title>The Young Adult's Guide to Investing by Rob Pivnick</title>
        <meta name="description" content="A practical guide to finance that helps young people plan, save, and get ahead." />
        <link rel="canonical" href="https://youngadultsguide.com" />
      </Helmet>
      <Navbar />
      {children}
      <Footer />
    </>
  )
}

export default Layout;