import React, { useState } from "react";
import { Link } from "gatsby";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faShoppingCart, faDownload } from "@fortawesome/free-solid-svg-icons";
import "../styles/global.sass";
import "../styles/navbar.sass";

const Navbar = () => {
	const [menuOpen, setMenuOpen] = useState(false);
	return (
		<nav className="navbar is-dark">
			<div className="container navbar-container">
				<div className="navbar-brand">
					<Link className="navbar-item" to="/">
						<strong>Young Adult's Guide to Investing</strong>
					</Link>
					<div role="button" tabIndex="-1"
						onKeyDown={() => setMenuOpen(!menuOpen)}
						onClick={() => setMenuOpen(!menuOpen)}
						className={`navbar-burger ${menuOpen ? "is-active" : ""}`}
					>
						<span></span>
						<span></span>
						<span></span>
					</div>
				</div>

				<div className={`navbar-menu is-dark ${menuOpen ? "is-active" : ""}`}>
					<Link className="navbar-item" to="/">
						Home
					</Link>
					<Link className="navbar-item" to="/about">
						About the Author
					</Link>
					<Link className="navbar-item" to="/reviews">
						Reviews and News
					</Link>
					<div className="navbar-end">
						<p className="control navbar-item">
							<Link
								className="button is-default"
								to="/#action-items"
							>
								<span className="icon">
									<FontAwesomeIcon icon={faDownload} />
								</span>
								<span>Download a sample</span>
							</Link>
						</p>
						<p className="control navbar-item">
							<a
								href="https://www.amazon.com/Young-Adults-Guide-Investing-Practical-ebook/dp/B07ZZGXYLB/ref=tmm_kin_swatch_0?_encoding=UTF8&qid=1618234737&sr=8-2"
								className="button is-link"
								target="_blank"
								rel="noreferrer"
							>
								<span className="icon">
									<FontAwesomeIcon icon={faShoppingCart} />
								</span>
								<span>Get a copy</span>
							</a>
						</p>
					</div>
				</div>
			</div>
		</nav>
	);
};

export default Navbar;
